<template>

    <div class="socialize-modal-image-chat">
        <template v-if="itemSelectedNormalized >= 0">

            <transition name="fade" appear>
                <div
                     @click="onClickCloseModal"
                     class="modal fade show modal-image-chat overflow-auto"
                     :style="{ display : 'block' }"
                     tabindex="-1" role="dialog" aria-labelledby="gridModalLabel">

                    <!-- close button -->
                    <a class="close-modal text pt-sm-4" href="#" @click.prevent.stop="onClickCloseModal">
                        <span class="mdi mdi-close"></span>
                    </a>

                    <div class="modal-dialog modal-dialog-centered justify-content-center position-relative" role="document"
                         :style="{ 'max-width':'unset', }">

                        <!-- slider left -->
                        <div v-if="items.length > 1" class="slider left">
                            <a href="#" @click.prevent.stop="onClickSlideLeft">
                                <span class="mdi mdi-chevron-left"></span>
                            </a>
                        </div>

                        <!-- content -->
                        <div class="overflow-hidden">
                            <div class="modal-content position-relative">

                                <div class="modal-body p-0">

                                    <!-- loading animation -->
                                    <div ref="loading" class="spinner-rect-5 loading">
                                        <div class="rect1"></div>
                                        <div class="rect2"></div>
                                        <div class="rect3"></div>
                                        <div class="rect4"></div>
                                        <div class="rect5"></div>
                                    </div>

                                    <!-- v-if="items[itemSelected] && typeof items[itemSelected] === 'string'" -->
                                    <div v-if="!waiting"
                                         class="container-fluid d-flex flex-column flex-md-row px-0 m-0"
                                    >

                                        <!-- image on left side -->
                                        <a v-lazy-container="{ selector : 'img' }"
                                           ref="shadow"
                                           :class="[
                                                'd-flex align-items-center justify-content-center position-relative shadow-trigger',
                                                !!items[itemSelectedNormalized].title ? '' : 'shadow-hidden'
                                           ]"
                                           :style="{
                                                'max-height': '100%',
                                                'overflow'  : 'hidden' // dont show blur
                                            }"
                                            :href="items[itemSelectedNormalized].href"
                                            :title="items[itemSelectedNormalized].title"
                                           @mousemove="setAnimationShadow"
                                        >
                                            <div class="shadow top"></div>
                                            <div class="shadow title text-truncate p-1 p-md-4">{{ items[itemSelectedNormalized].title }}</div>
                                            <img :alt="items[itemSelectedNormalized].title"
                                                 :data-src="items[itemSelectedNormalized].uri"
                                                 class="blur position-absolute"
                                            />
                                            <img :alt="items[itemSelectedNormalized].title"
                                                 :data-src="items[itemSelectedNormalized].uri"
                                                 class="img-fluid"
                                                 style="z-index: 1;"
                                                 @loading="onImageLoading($event, 'loading')"
                                                 @loaded="onImageLoading($event, 'loaded')"
                                                 @error="onImageLoading($event, 'error')"
                                            />
                                            <div class="shadow bottom"></div>
                                        </a>

                                        <!-- white text block on right side -->
                                        <div
                                            v-if="socialize.aEntities.length"
                                            class="px-0 m-0 d-flex flex-column bg-white news-and-chat"
                                        >
                                            <slot name="top-right"></slot>
                                            <template v-for="(entity, i) in socialize.aEntities">
                                                <!-- initialize all, but show only 1 -->
                                                <!-- so no re-render is necessary -->
                                                <div :class="[ i === itemSelectedNormalized ? 'd-flex' : 'd-none', 'flex-grow-1' ]">
                                                    <vue-chat
                                                        :user="socialize.oUser"
                                                        :fallback="socialize.fallback"
                                                        :entity="entity"
                                                        :overlay="true"
                                                    ></vue-chat>
                                                </div>
                                            </template>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- slider right -->
                        <div v-if="items.length > 1" class="slider right" @click.prevent.stop="onClickSlideRight">
                            <a href="#">
                                <span class="mdi mdi-chevron-right"></span>
                            </a>
                        </div>

                    </div>
                </div>

            </transition>

            <transition name="fade" appear>
                <div class="modal-backdrop fade show background"></div>
            </transition>

        </template>
    </div>

</template>

<script>
    import _throttle      from 'lodash/throttle';
    import _get           from 'lodash/get';
    import _isPlainObject from 'lodash/isPlainObject';

    import Chat           from './socialize/Chat.vue';
    import Mogile         from './../../js/layout/wd18774/Mogile';

    export default {
        components : {
            'vue-chat' : Chat
        },
        props: {
            modalOpenSelector : {
                type    : String,
                required: false,
                default : '', // .images-<hash> .image-tile-layout > a > div
                validator( value ) {
                    return true; // TODO
                }
            },
            items: {
                type    : Array,
                required: true,
                default : [], // { href, title, uri }
                validator( value ) {
                    return true; // TODO
                }
            },
            lazyLoadAmount: {
                type    : Number,
                required: false,
                default : 3,
                validator( value ) {
                    return true; // TODO
                }
            },
            socialize: {
                type     : Object,
                required : false,
                default() {
                    return {
                        aEntities: [], // { id : 1, type : 'image' }, ... { id : 9999, type : 'image' }
                        fallback : {
                            user: {
                                image  : "/static/images/placeholder/v3/no_user_v3_100_100.png",
                                name   : "",
                                premium: false,
                                profile: "",
                            }
                        },
                        oUser    : {
                            canDelete: false,
                            id       : 0,
                            image    : "",
                            name     : "",
                            premium  : false,
                            profile  : "/user/firstname-lastname-0"
                        }
                    };
                },
                validator( value ) {
                    return true; // TODO
                },
            },
        },
        data() {
            return {
                itemSelected : -1,
                waiting      : false,
                animate      : true,
                animation    : 'animation fadeInRight',
            }
        },
        computed: {
            itemSelectedNormalized: function(){
                if(this.itemSelected >= 0) // not -1
                {
                    // only request, if current image has to be loaded
                    // to avoid load next -> fetches always 3, would always fire ajax
                    const hashToImageSelected = _get(this.items, this.itemSelected+'.uri');
                    const bMogile = _isPlainObject(hashToImageSelected);

                    if(bMogile){
                        const from = this.itemSelected;
                        const to   = Math.min(this.itemSelected + this.lazyLoadAmount-1, this.items.length-1);

                        const aHashToImage      = [];
                        const aHashToImageIndex = [];
                        for(let i = from; i<=to; i++)
                        {
                            const hashToImage = _get(this.items, i+'.uri');
                            if(_isPlainObject(hashToImage))
                            {
                                aHashToImage.push(hashToImage);
                                aHashToImageIndex.push(i);
                            }
                        }

                        if(aHashToImage.length){
                            // console.log('ModalImageChat : mogile -> fetchUrl');
                            this.waiting = true;
                            Mogile.fetchUrls({aMogileParameters : aHashToImage}).then((images) =>{
                                // console.log('ModalImageChat : mogile -> fetchUrl -> response', images);
                                if(images.length !== aHashToImageIndex.length){
                                    console.log( // keep this!
                                        'ModalImageChat, error : MogileFetch-image-amount != requested',
                                        {aHashToImage, aHashToImageIndex, mogileResponse : images}
                                    );
                                }
                                aHashToImageIndex.forEach((iSelected, iIterate)=>{
                                    this.items[iSelected]['uri'] = _get(images, iIterate.toString(), this.items[iSelected]['uri']);
                                });
                                this.waiting = false;
                            });
                        }
                    }
                }

                return this.itemSelected;
            }
        },
        watch: {},
        methods: {
            close(){
                this.itemSelected = -1;
            },
            onClickSlideLeft(event)
            {
                let itemBefore = this.itemSelected-1,
                    aboveZero  = itemBefore + this.items.length,
                    result     = (aboveZero) % (this.items.length);

                this.itemSelected = result;

                this.onImageLoading(null, 'loading'); // show loading-css-animation earlier
            },
            onClickSlideRight(event)
            {
                let itemAfter  = this.itemSelected+1,
                    aboveZero  = itemAfter + this.items.length,
                    result     = (aboveZero) % (this.items.length);

                this.itemSelected = result;

                this.onImageLoading(null, 'loading'); // show loading-css-animation earlier
            },
            onClickCloseModal(event)
            {
                if(
                    event.target.getAttribute('role') === 'dialog' ||
                    event.target.getAttribute('class').includes('close')
                ){ // clicked background
                //     setTimeout(() => document.body.classList.remove('overflow-hidden'), 200); // animation-delay
                    this.close();
                }
            },
            onClickShowModal()
            {
                if(this.modalOpenSelector.length)
                {
                    let nodes = document.querySelectorAll(this.modalOpenSelector);

                    if(this.items.length > 0 && nodes.length !== this.items.length){
                        console.log('warning - ModalImageChat.vue, onClickShowModal, items !== selector', {
                            items : this.items, selector : this.modalOpenSelector, nodes : nodes
                        })
                    }

                    nodes.forEach((elem,i) => elem.addEventListener('click', ()=>{

                        // console.log('ModalImageChat', { // debug
                        //     nodes,
                        //     modalOpenSelector : this.modalOpenSelector,
                        //     selection : i,
                        // });

                        this.itemSelected = i;
                        // document.body.classList.add('overflow-hidden');
                    }, false));
                    if(!nodes.length){
                        console.log('ModalImageChat, imageSelector : no dom elements found', {selector : this.modalOpenSelector});
                    }
                }else{
                    console.log('ModalImageChat, imageSelector : empty')
                }
            },
            setAnimationShadow : _throttle(function(event){
                if (this.$refs.shadow) // fix on closing modal
                {
                    this.$refs.shadow.classList.remove('shadow-trigger');
                    this.$refs.shadow.offsetWidth; /** @see https://css-tricks.com/restart-css-animation/ */
                    this.$refs.shadow.classList.add('shadow-trigger');
                }
            }, 500), // needs to be the same as .shadow-out @ transition-delay
            onImageLoading(event, state)
            {
                if(['error','loaded'].includes(state)) {
                    this.$refs.loading.classList.add('loading-done');
                }else{ // loading
                    this.$refs.loading.classList.remove('loading-done');
                }
            }
        },
        created(){},
        mounted(){
            this.onClickShowModal();
        }
    }
</script>

<style lang="scss" scoped>
    .modal-content {
        background: none !important;
    }
    .slider {
        color          : #707070;
        font-weight    : bold;
        font-size      : 5em;
        pointer-events : auto; /* to register auto-clicks */

        /* webflow fix */
        width          : auto;
        height         : auto;

        &.left {
            position : fixed; /* image to large, always vertical centered */
            left     : 0;
            top      : 50%;
            transform: translateY(-50%);
            z-index  : 3; /* visible above image */

            border-radius: 50px;
            line-height  : 1em;
            /*background   : rgba(0, 0, 0, 0.05);*/ /* opacity like without "opcatiy :value" on whole content */
        }
        &.right {
            position : fixed; /* image to large, always vertical centered */
            right    : 0;
            top      : 50%;
            transform: translateY(-50%);
            z-index  : 3; /* visible above image */

            border-radius: 50px;
            line-height  : 1em;
            /*background   : rgba(0, 0, 0, 0.05);*/ /* opacity like without "opcatiy :value" on whole content */
        }

        .mdi {
            color : #707070; /* overwrite external style-definitions : user-agent, webflow links*/
        }
    }
    .background {
        background-color : black !important;
        opacity          : 0.8 !important;
    }
    .fade-enter-active, .fade-leave-active {
        transition: opacity 0.5s;
    }
    .fade-enter, .fade-leave-to {
        opacity: 0;
    }
    .slide-enter-active, .slide-leave-active {
        transition: opacity 0.5s;
    }
    .slide-enter, .slide-leave-to {
        opacity: 0;
    }

    img {
        &[lazy=loading] {
            background : none; /* rgba(0, 0, 0, 0.05); */
        }
        &[lazy=loaded], &[lazy=error] {
            background : black;
            transition : background 0.5s;
            width      : 100%; /* ie max-width not working, needs width first */
        }
        &.blur {
            filter         : blur(12px) brightness(200%) opacity(50%);
            -webkit-filter : blur(12px) brightness(200%) opacity(50%);
            /*transform      : scale(1.25);*/ /* deactivated, overlaps with message area */

            width    : 100%;
            height   : 100%;

            &[lazy=loading] {
                filter     : none;
            }
        }
    }

    .loading {
        position       : fixed;
        z-index        : 2;
        top            : 50%;
        transform      : translate(-50%, -50%);
        left           : 50%;

        background     : black;
        opacity        : 0.5;
        border-radius  : 10px;
        padding-top    : 10px;
        height         : 70px;
        padding-bottom : 10px;

        &-done {
            display : none;
        }
    }

    a.close-modal {
        position    : absolute;
        top         : 0;
        right       : 0;
        z-index     : 3; /* 2 = above image, 1 : above text-block, 3 = above shadow */
        font-weight : bold;
        font-size   : 2.5rem;
        color       : #999;
        line-height : 1em;
    }

    .mdi:hover {
        color      : white;
        opacity    : 1;
        transition : opacity 250ms, color 250ms;
    }

    .shadow {
        position   : absolute;
        width      : 100%;
        left       : 0;
        box-shadow : 0 0 50px 50px rgba(56, 56, 56, 0.75) !important;
        z-index    : 2;
        opacity    : 1;

        &-hidden {
            .shadow.top, .shadow.bottom, .title {
                display: none;
            }
        }

        &-trigger {
            .shadow.top, .shadow.bottom, .title {
                opacity          : 0;
                transition       : opacity 1s;
                transition-delay : 500ms;
            }
        }

        &.top {
            top        : 0;
        }
        &.bottom {
            bottom     : 0;
        }

        &.title {
            top           : 0; // cant use %, cause higher than wider -> not same distance
            left          : 0; // cant use %, cause higher than wider -> not same distance
            box-shadow    : none !important;
            color         : white;
            max-width     : calc(100%); // 100% - left - right
            font-size     : 2rem;
            line-height   : 2.5rem; // > font-size, so not cut off
        }
    }
</style>