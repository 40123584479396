/**
 * catch mouse hover on animal-link and show animal-details near mouse position
 *
 * animal-details are retrieved by ajax and returned as html to show near mouse position
 *
 * a[href*="dogs-details"]                   -> triggers ajax and shows animal-information
 * a[href*="dogs-details"].no-animal-overlay -> prevent
 */
class AnimalOverlay
{
    constructor({sAjaxUri, sClassName}) {

        this._iAnimalID = null;
        this._sAjaxUri = '';
        this._iTimerID = null;
        this._sCurrentAjax = null;
        this._iMousePosX = 0;
        this._iMousePosY = 0;
        this._oThis = null;
        this._iDistance = 10;

        this._iClientX = 0;
        this._iClientY = 0;
        this._iPageX = 0;
        this._iPageY = 0;

        this._iScreenWidth = 0;
        this._iScreenHeight = 0;
        this._stopMove = false;

        this._setAjaxUri( sAjaxUri );
        this._getSystemCoords();
        this._setEventOnElements( sClassName );

    }

    _setAjaxUri( sUri )
    {
        this._sAjaxUri = sUri;
    }

    _getSystemCoords()
    {
        const _this = this;
        $( document ).mousemove( function ( e ) {
            _this._iPageX = e.pageX;
            _this._iPageY = e.pageY;
            _this._iClientX = e.clientX;
            _this._iClientY = e.clientY;
            _this._iScreenWidth = $( window ).width();
            _this._iScreenHeight = $( window ).height();
        } );
    }

    _setEventOnElements( sSelector )
    {
        var bVisible = false;
        const _this = this;

        // old - without support of live-dom-updates
        // $( sSelector ).hover( mouseEnter, mouseLeave );

        // new - with support of live-dom-updates (e.g. using slick changes dom by breakpoints)
        $('body')
            .off('mouseenter', sSelector) // prevent, if called multiple times
            .off('mouseleave', sSelector) // prevent, if called multiple times
            .on('mouseenter', sSelector, mouseEnter)
            .on('mouseleave', sSelector, mouseLeave)
        ;

        function mouseEnter(event) {
            bVisible = true;

            _this._oThis = $( this );

            this._iTimerID = setTimeout( function () {
                if(!bVisible)
                {
                    return false;
                }

                _this._iAnimalID = _this.getAnimalId();
                if (_this._iAnimalID < 1) {
                    return false;
                }

                _this._stopMove = false;
                $(document).bind('mousemove', mouseMove);

                if ( _this._isContentCached() ) {
                    _this._handleDisplayingOverlay( $( 'body' ).data( _this._iAnimalID ) );
                } else {
                    _this._startAjax();
                }

            }, 400 );
        }
        function mouseLeave() {
            bVisible = false;
            $(document).unbind('mousemove', mouseMove);
            _this._abortAjax();
            _this._handleDisplayingOverlay();
        }

        function mouseMove() {
            if (_this._stopMove) {
                return false;
            }
            var oElement = $( '.animalOverlay2#animal_' + _this._iAnimalID );
            _this._calcPosition();
            oElement.css( 'top', _this._iMousePosY );
            oElement.css( 'left', _this._iMousePosX );
        }
    }

    getAnimalId()
    {
        if ( this._oThis.is( 'a' ) ) {
            var $oA = this._oThis;
        } else {
            var oOverlay = this._oThis.find( 'a[class*="no-animal-overlay"]' );
            if (oOverlay.length > 0) {
                return 0;
            }
            var $oA = this._oThis.find( 'a[href*="dogs-details"]' );
        }

        return HELPER.parseIdFromAHref( $oA );
    }

    _startAjax()
    {
        const _this = this;
        this._abortAjax(); // if old ajax is running

        this._sCurrentAjax = $.post( this._sAjaxUri, { 'iAnimalID' : this._iAnimalID }, function ( data ) {
            $( 'body' ).append( data );
            _this._handleDisplayingOverlay( data );
            $( 'body' ).data( _this._iAnimalID, data ); // Caching

            var oElement = $( '.animalOverlay2#animal_' + _this._iAnimalID  );

        }, 'html' );
    }

    _abortAjax()
    {
        clearTimeout( this._iTimerID );

        if ( this._sCurrentAjax ) {
            this._sCurrentAjax.abort();
        }
    }

    _handleDisplayingOverlay( data )
    {
        var oElement = $( '.animalOverlay2#animal_' + this._iAnimalID );

        if ( typeof data == 'undefined' || data.length == 0 ) {

            this._stopMove = true;

            $(".animalOverlay2").fadeOut( 'fast', function() {
                //$(this).hide();
            } );
        } else {
            if( $('.animalOverlay2--pedigree-fix').length === 0 &&
                $(".pedigreeOverlay2:hover").length === 0
            ) {
                oElement.css( 'display', 'none' );
            } else {
                this._calcPosition();
                oElement.css( 'position', 'absolute' );
                oElement.css( 'top', this._iMousePosY );
                oElement.css( 'left', this._iMousePosX );
                oElement.fadeIn( 300 );
            }
        }
    }

    _calcPosition()
    {
        const buffer = 10;
        const overlayWidth = 550;


        if (this._iPageX + this._iDistance + overlayWidth + buffer > this._iScreenWidth)
        {
            this._iMousePosX = this._iPageX - this._iDistance - overlayWidth; // left to cursor
        } else {
            this._iMousePosX = this._iPageX + this._iDistance; // right to cursor
        }

        this._iMousePosY = this._iPageY + this._iDistance;
    }

    _isContentCached()
    {
        return !( typeof $( 'body' ).data( this._iAnimalID ) == 'undefined' || $( 'body' ).data( this._iAnimalID ).length == 0 );
    }
}

export default AnimalOverlay;